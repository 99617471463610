import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { IconProvider } from "./context/Context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <IconProvider>
  <React.StrictMode>
      <App />
  </React.StrictMode>
    </IconProvider>
);
